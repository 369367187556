
import Vue, {PropType} from "vue";
import {ProductModelFactory} from "../../model/product/ProductModelFactory";
import {
  canBeConfigured,
  getProductOverviewImage,
  getProductUrl,
} from "../../model/ProductBusinessLogic";
import {ConfigurationDataFlat} from "kistenkonfigurator/build/property/configuration";
import {ProductDTO} from "../../model/rest/data-contracts";

export default Vue.extend({
  props: {
    product: {
      type: Object as PropType<ProductDTO>,
      default: () => ProductModelFactory.createEmptyOverviewProduct(),
      required: true,
    },
    configuration: {
      type: Object as PropType<ConfigurationDataFlat>,
      default: () => ({}),
      required: false,
    },
    /**
     * Allows to override the current product image. This can be used, if a component user likes to display
     * an image that is currently not available on the server. E.g. if an image is generated from the configurator.
     * This is most likely an DataURL.
     */
    productImageUrl: {
      type: String as PropType<string>,
      default: null,
      required: false,
    },
  },
  computed: {
    productImageAltText(): string {
      return this.product.name;
    },
    productLinkLabelKey(): string {
      const configurable = canBeConfigured(this.product) || false;
      return configurable ? "pages.productOverview.linkLabel.configurator" : "pages.productOverview.linkLabel.fix";
    },
  },
  mounted(): void {
    this.imageTag().onerror = () => this.handleImageError();
  },
  methods: {
    imageTag(): HTMLImageElement {
      return this.$refs!.imageTag! as any;
    },
    getProductUrl(): string {
      return getProductUrl(this as any, this.product, this.configuration);
    },
    defaultProductImage(): string {
      return getProductOverviewImage(this.$axios.defaults.baseURL!, this.product);
    },
    handleImageError(): void {
      if (this.imageTag().src !== this.defaultProductImage()) {
        this.imageTag().src = this.defaultProductImage();
      }
    },
  },
});
