<template>
  <div class="cookie-consent" v-if="!consentGiven">
    <b-container>
      <b-row>
        <b-col class="col-md-10 col-12 mb-4 mb-md-0">
          {{ $t("components.cookieConsent.message") }}
        </b-col>
        <b-col class="col-md-2 col-12">
          <b-button id="accept-cookie-consent" @click="consentAccepted()">
            {{ $t("components.cookieConsent.buttonText") }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
const LOCAL_STORAGE_KEY = "cookie:accepted";

export default {
  name: "CookieConsent",
  data() {
    return {
      consentGiven: false,
    }
  },
  mounted() {
    this.consentGiven = !!localStorage.getItem(LOCAL_STORAGE_KEY);
  },
  methods: {
    consentAccepted() {
      this.consentGiven = true;
      localStorage.setItem(LOCAL_STORAGE_KEY, "true");
    }
  }
}
</script>
